import { useRouter } from 'next/router';
import useSWR from 'swr';
import { apiGet } from '../helper';

export default function useCompany() {
    const router = useRouter();
    let { companyId } = router.query;

    const { data, isLoading, mutate, error } = useSWR(
        `/company/${companyId}`,
        apiGet,
    );

    return [data, isLoading, mutate, error];

    // const { loading, error, data, refetch } = useQuery(GET_COMPANY, {
    //     variables: {
    //         id: companyId,
    //     },
    //     skip: !companyId,
    // });

    // let company = data ? data?.companies_by_pk : undefined;

    // return [company, loading, refetch, error];
}
